@import 'config/typography';
@import 'config/reset';
@import 'vendor/swup';
@import '~glightbox/dist/css/glightbox.min.css';
@import 'vendor/glightbox';

@import 'global';

@import 'components/exhibition-list';
@import 'components/exhibition-header';
@import 'components/exhibition-gallery';
@import 'components/logo';
@import 'components/info-section';

@import 'pages/exhibition';
@import 'pages/about';
