.goverlay {
    background: black;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background: transparent;
    :focus {
        outline: 0;
    }
}
.glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background: transparent;
}

.glightbox-clean .gbtn.gprev, .glightbox-clean .gbtn.gnext {

    display: none;

    svg {
        display: none;
    }
    width: 50%;
    height: calc(100% - 50px);
    top: 50px;
    background: transparent!important;
}

.glightbox-clean .gbtn.gprev {
    left: 0;
}

.glightbox-clean .gbtn.gnext {
    left: 50%;
}

.glightbox-clean .gslide-description,
.glightbox-mobile .glightbox-container .gslide-description {
    background: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .6rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    // padding: 0;

    color: white;

    position: fixed;
    top: 100vh;
    left: 20px;
    width: 70vh;
    max-width: unset!important;
    transform: rotate(-90deg);
    transform-origin: 0 0;
}

.glightbox-mobile .glightbox-container .gslide-desc {
    color: unset;
}

.glightbox-clean .gdesc-inner {
    padding: 0 20px;
}

.gslide-image img {
    max-height: 100vh!important;
}

// @media screen and (orientation: landscape) {
//     .gslide-image img {
//         height: 100%;
//     }
// }

// @media (min-width: 769px) {
//     .glightbox-container .ginner-container {
//         height: 100%;
//     }
// }

.gslide.current .gslide-description {
    opacity: 1;
    transition: opacity .1s linear;
}

.gslide.gslideInLeft .gslide-description,
.gslide.gslideOutLeft .gslide-description,
.gslide.gslideInRight .gslide-description,
.gslide.gslideOutRight .gslide-description {
    opacity: 0;
}