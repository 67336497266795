.exhibition-text {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    font-size: .8rem;
    line-height: 1.3em;
    margin-bottom: 50px;

    p {
        text-indent: 2rem;
        margin-bottom: 1em;
    }

    .video {
        width: 100%;
        height: 350px;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
}