.logo {
    // bottom: 1rem;
    // left: 1rem;
    display: inline;
    height: 50px;
    transition: transform .2s linear;
    z-index: 3000;
    
    body.black & {
        filter: invert(1);
        transform: rotateY(180deg);
    }

    @media (min-width: 600px) {
        top: 1rem;
        right: 1rem;
        left: unset;
    }

    &-container {
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
}