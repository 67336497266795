.info-section {
    display: flex;
    flex-wrap: wrap;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .6rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    justify-content: left;

    padding: 8px;
    border-bottom: 1px solid;
    
    & > * {
        text-align: left;
        &:first-child {
            flex-grow: 1;
        }
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    @media (min-width: 600px) {
        padding: 0;
        padding-bottom: 8px;
        position: fixed;
        top: 100%;
        left: 20px;
        width: 100vh;
        transform: rotate(-90deg);
        transform-origin: 0 0;

        & > * {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            text-align: right;
            flex-grow: 1;
            &:first-child {
                flex-grow: 0;
            }
        }
    }
}

nav {
    font-family: Arial, Helvetica, sans-serif;
    font-size: .6rem;
    text-transform: uppercase;
    letter-spacing: 1px;

    .element {
        // display: inline-block;
        // margin: 0 1rem;
        
        &.active {
            border-bottom: 1px solid;
        }
    }
}