.exhibition-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // border-bottom: 2px solid;
    margin-bottom: 50px;
    padding-bottom: 50px;

    body.tmp-exhibition & {
        margin-top: 50px;
    }

    .number {
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: .5rem;
        transform: scale(.8);
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 1.5rem;
        height: .9rem;

        border-top: 2px solid;
        border-bottom: 2px solid;

        &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            right: -6px;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: black;
            body.black & {
                background: white;
            }
        }
        
        // border: 2px solid;
        // border-radius: 50%;
    }
    .title {
        font-size: 1.6rem;
        letter-spacing: -0.04em;
        max-width: 888px;
    }
    img {
        width: 200px;
        display: inline-block;
        margin-top: .5rem;
        transition: transform .1s linear;
        &:hover {
            transform: rotate(1deg);
        }
    }
    .date {
        display: flex;
        width: 450px;
        max-width: 100%;
        margin-top: .5rem;
        font-family: Arial, Helvetica, sans-serif;
        span {
            flex-grow: 1;
            position: relative;
            color: transparent;
            &::before, &::after {
                content: '';
                border-bottom: 1px solid;
                position: absolute;
                width: calc(100% - 10px);
                left: 5px;
                transform: rotate(-1deg);
                color: black;
                body.black & {
                    color: white;
                }
            }
            &::after {
                top: .4rem;
            }
            &::before {
                top: .6rem;
            }
        }
    }
    .intro {
        font-family: Arial, Helvetica, sans-serif;
        font-size: .6rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        width: 450px;
        max-width: 100%;
        margin-top: .5rem;
    }
}