.about-text {
    font-family: Arial, Helvetica, sans-serif;

    figure {
        margin-bottom: 2rem;
    }
}

.about-logo {
    cursor: pointer;
    margin: 0 auto;
    padding: 3rem;
    transition: transform .2s linear;

    body.black & {
        filter: invert(1);
        transform: rotateY(180deg);
    }
}
    