html, body {
    font-family: "Prata";
    font-size: 20px;
    // color: white;
    // background: black;
    text-align: center;
}

body {
    &.black {
        background-color: black;
        transition: background-color .2s linear;
        color: white;
    }
}

main {
    // text-transform: uppercase;
    padding: .5rem;
    @media (min-width:600px) and (max-width:1000px) {
        padding-left: 100px;
    }
}

p a {
    border-bottom: 1px dotted;
}