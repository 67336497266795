[style*="--aspect-ratio"] {
    position: relative;
}

[style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: var(--aspect-ratio);
}

[style*="--aspect-ratio"] img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.exhibition-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;

    .exhibition-image {
        position: relative;
        margin-bottom: 80px;
        width: 100%;

        &[data-orientation="portrait"] {
            width: 66.6666%;
        }

        figure {
            margin: 0 auto;
        }

        figcaption {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.6rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: left;
            margin-top: 1em;

            .nmbr {
                margin-right: 2em;
            }
        }
    }
}